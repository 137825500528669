'use strict'

const TabModuleAnimations = {

  init: function () {
    this.titleApparitionByLine();
    this.blockApparitionSlideIn();
    this.splitTextAnimation();
  },
  // Ajouter la class title-animated ainsi que les <br/> dans le markup.
  // L'animation sera joué quand son parent aura la class visible 
  // Ajouter sur le markup un attribut data-delay si besoin, sinon 100 par default
  titleApparitionByLine: function () {
    const titles = document.querySelectorAll('.title-animated');
    titles.forEach(title => {
      const newInnerHTML = title.innerHTML
        .split(/<br>\s*/i)
        .map((text, i) => '<span class="title-animated__line"><span style="animation-delay:' + ((title.getAttribute('data-delay') ? parseInt(title.getAttribute('data-delay')) : 0) + (0.3 * (i + 1))) + 's">' + text + '</span></span>')
        .join('');

      title.innerHTML = newInnerHTML;
    })
  },

  blockApparitionSlideIn: function () {
    // Fonction pour appliquer la classe "appear" avec un délai croissant
    function addDelayedClass(element, index) {
      setTimeout(() => {
        if (element.classList.contains('js-clipped-img') && !element.classList.contains('appear')) {
          TabModuleAnimations.clippedImg(element).animatedClipPath();
        }
        element.classList.add('appear');
      }, (index + 1) * 300)
      //NB: index + 1 parce que 0 délai pose problème si première image au dessus de la ligne de flottaison 
    }

    // Fonction pour envelopper le contenu d'un élément dans un span
    function wrapInnerContent(element) {
      const content = element.innerHTML;
      element.innerHTML = `<span class="js-span">${content}</span>`;
    }

    // Parcourir les éléments au chargement de la page et les envelopper dans un span
    const elements = document.querySelectorAll('.js-slide-in .js-text-element');
    if (elements.length > 0) {
      elements.forEach((element, index) => {
        wrapInnerContent(element);
      });
    }

    // Fonction de rappel pour observer les changements d'intersection
    function handleIntersection(entries, observer) {

      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const parent = entry.target;
          const imgs = parent.querySelectorAll('.js-clipped-img:not(.appear)');
          // Parcourir les éléments enfants et faire apparaitre l'img avec un angle de 25 degrés
          if (imgs.length > 0) {
            imgs.forEach((element, index) => {
              TabModuleAnimations.clippedImg(element, index);
            });
          }
          const elements = parent.querySelectorAll('.js-text-element, .js-title-element, .js-clipped-img:not(.appear), .js-fade-element');
          // Parcourir les éléments enfants et ajouter la classe "appear"
          if (elements.length > 0) {
            elements.forEach((element, index) => {
              addDelayedClass(element, index);
            });
          }
          // Arrêter d'observer une fois que le bloc parent est dans la vue
          observer.unobserve(parent);
        }
      });
    }
    // Sélectionner tous les blocs parents avec la classe "js-slide-in"
    const parentElements = document.querySelectorAll('.js-slide-in');

    // Créer une instance de l'observateur d'intersection pour chaque bloc parent
    if (parentElements.length > 0) {
      const options = {
        root: null,
        rootMargin: '-20% 0% -20% 0%',
        threshold: 0, // Déclencher l'intersection lorsque l'élément est entièrement visible
      };
      parentElements.forEach((parentElement) => {
        const observer = new IntersectionObserver(handleIntersection, options);
        observer.observe(parentElement);
      });
    }
  },

  splitTextAnimation: function () {
    this.titles = document.querySelectorAll(".js-title-element");
    if (this.titles.length > 0) {
      this.titles.forEach(txt => {
        txt.vitesse = txt.dataset.vitesse ? txt.dataset.vitesse : 0.3;
        let save = txt.innerHTML;
        let data = txt.innerHTML.trim().split(" ");
        let tampon = "";
        txt.paragraphes = [];
        let height = -1;
        let lineHeight = 0;
        txt.innerHTML = "";

        for (let i = 0; i < data.length; i++) {
          txt.innerHTML += data[i] + " ";
          let currentHeight = txt.getBoundingClientRect().height;

          if (height === -1) {
            lineHeight = currentHeight;
            height = currentHeight;
          }

          if (height != currentHeight) {
            height = currentHeight;
            txt.paragraphes.push(tampon.substring(0, tampon.length - 1));
            tampon = "";
          }
          tampon += data[i] + " ";
        }

        txt.paragraphes.push(tampon.substring(0, tampon.length - 1));

        txt.innerHTML = "";
        txt.paragraphes.forEach((p, i) => {
          let para = document.createElement("p");
          para.innerHTML = "<span style='transform:translateY(" + lineHeight + "px);transition:transform " + txt.vitesse + "s ease " + (i * txt.vitesse * 0.5) + "s'>" + p + "</span>";
          para.classList.add("paragraphe")
          para.style.lineHeight = lineHeight + "px";
          txt.appendChild(para);
        })

        setTimeout(() => {
          txt.innerHTML = save;
        }, txt.paragraphes.length * txt.vitesse * 1000)
      })
    }
  },

  clippedImg: function (img) {
    img.angle = 25 * 2 * Math.PI / 360; //angle de 25 degrés
    img.reverse = img.dataset.reverse ? img.dataset.reverse : false;
    img.delay = img.dataset.delay ? img.dataset.delay : 0;
    let { width, height } = img.getBoundingClientRect();
    
    let heightForTan = Math.tan(img.angle) * width;

    img.style.clipPath = `polygon(
          0px ${height + heightForTan}px,
          ${width}px ${height + heightForTan}px,
          ${width}px ${img.reverse ? height : height + heightForTan}px,
          0px ${img.reverse ? height + heightForTan : height}px
      )`;

    function AnimatedClipPath() {
      img.style.clipPath = `polygon(
              0 ${height + heightForTan}px,
              ${width}px ${height + heightForTan}px,
              ${width}px ${img.reverse ? -heightForTan : 0}px,
              0 ${img.reverse ? 0 : -heightForTan}px
          )`;
    }

    AnimatedClipPath.animatedClipPath = AnimatedClipPath;
    return AnimatedClipPath;
  }
}

export default TabModuleAnimations;