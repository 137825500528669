'use strict';
import Swiper from 'swiper';
import 'swiper/css';

import RiseUpDecal from "./riseupDecal.js"

const trombi = {
    fullUrl : data_api['url_members'],
    filters: new Array(),
    results: new Array,
    filtreElt : null,
    tablLine:null,
    listElt: null,
    loader:null,
	init: function(){
        const trombiElt = document.querySelector('.c-trombi');
        if(trombiElt) {
            trombi.filtreElt = trombiElt.querySelector('.c-trombi__filter');
            trombi.tablLine = trombi.filtreElt.querySelector('.tablLine--');
            trombi.listElt = trombiElt.querySelector('.c-trombi__list');
            trombi.addCurrentFilter(trombi.filtreElt.querySelector('li:first-of-type'));//Ajout le current et simule le click sur le filtre
            trombi.events();
            trombi.getParams();
            trombi.initSliderImage();
        }
    },
    getParams: () => {
        let params = (new URL(document.location)).searchParams;
        if(params.get('group') && trombi.filtreElt.querySelector('[data-id="'+params.get('group')+'"]')) { //s'il y a une pagination

            trombi.addCurrentFilter(trombi.filtreElt.querySelector('[data-id="'+params.get('group')+'"]'))
        }
      },
    events: function() {
        var elts = trombi.filtreElt.querySelectorAll('li:not(.tablLine--)');
        elts.forEach( (item, index) => {
            item.addEventListener('click', function(e) {
                e.preventDefault();
                // trombi.filtreElt.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                document.querySelector('.c-loader').classList.remove('hide--');
                trombi.listElt.querySelector('.c-trombi__content').classList.remove('show--');
                
                if (index === 0) {
                    document.querySelector('.c-trombi .f-accordeon').classList.add('show--');
                }
                else {
                    document.querySelector('.c-trombi .f-accordeon').classList.remove('show--');
                }

                var timer = setTimeout(function() {
                    trombi.addCurrentFilter(item);
                }, 100);
            })
        })
    },
    addCurrentFilter: function(elt) { //Ajoute la classe current au filtre actuel
        if(trombi.filtreElt.querySelector('li.current--'))
            trombi.filtreElt.querySelector('li.current--').classList.remove('current--');
        elt.classList.add('current--');
        trombi.tablLine.style.width = elt.offsetWidth+'px';
        trombi.tablLine.style.left = elt.offsetLeft+'px';
        trombi.filtering(elt.dataset.id, elt.dataset.subcategory);
    },
    filtering: function(eltId, subcategorySlug) {
        trombi.listElt.querySelectorAll('.c-trombi__item').forEach(function(elt) {
            elt.classList.remove('visible--');
            if(elt.dataset.filter.indexOf(eltId)<0) {
                elt.classList.add('hide--');
            }
            else if(elt.classList.contains('hide--')) {
                elt.classList.remove('hide--');
            }
        });
        RiseUpDecal.init();

        trombi.updateSlider(eltId, subcategorySlug);
        
        var timer = setTimeout(function() {
            document.querySelector('.c-loader').classList.add('hide--');
            document.querySelector('.c-trombi__content').classList.add('show--');
            clearTimeout(timer);
        }, 200);
        
    },
    initSliderImage: function() {
        const sliders = document.querySelectorAll('.c-trombi__slider');
        if (sliders.length > 0) {
            sliders.forEach(slider => {
                var swiper = new Swiper(slider, {
                    slidesPerView: 1,
                    spaceBetween: 24,
                    breakpoints: {
                        991: {
                          spaceBetween: 32,
                          slidesPerView: 4,
                        }
                    }
                });
            })
        }
    },
    updateSlider: function(eltId, subcategorySlug) {
        if (!subcategorySlug) {
            document.querySelectorAll('.c-trombi__slider').forEach(slider => slider.style.display = "none");
            return;
        }
        document.querySelectorAll('.c-trombi__slider').forEach(slider => slider.style.display = "block");
        const sliders = document.querySelectorAll('.c-trombi__slider');
        sliders.forEach(slider => {
            const sliderList = slider.querySelector('.c-trombi__slider-list');
            if (!sliderList) return;
            
            sliderList.innerHTML = ''; // Vider le slider actuel
    
            // Sélectionner tous les éléments cibles potentiels
            const itemsToClone = document.querySelectorAll('.c-trombi__item');
    
            itemsToClone.forEach(item => {
                // Extraire les valeurs de data-filter et vérifier si elles contiennent à la fois eltId et subcategorySlug
                let filters = item.dataset.filter ? item.dataset.filter.split('|') : [];
                if (filters.includes(eltId) || filters.includes(subcategorySlug)) {
                    if (filters.includes(subcategorySlug)) {
                        const clone = item.cloneNode(true);
                        clone.classList.remove('hide--');
                        const listItem = document.createElement('li');
                        listItem.className = 'swiper-slide';
                        listItem.appendChild(clone);
                        sliderList.appendChild(listItem);
                    }
                }
            });
    
            // Mettre à jour le Swiper si déjà initialisé
            if (slider.swiper) {
                slider.swiper.update();
            }
        });
    },
};

export default trombi;